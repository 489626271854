import React from "react"
import Navbar from "../components/navbar"
import resume from "../../static/resume.pdf"
import styled from "styled-components"

const ResumeFrame = styled.iframe`
  overflow: hidden;
  height: calc(100% - 55px);
  width: 100%; 
  position: absolute;
`
const OuterContainerWrapper = styled.div`
  margin-top: 55px;
`

export default function Resume() {
    return (
      <>
          <Navbar></Navbar>
          <OuterContainerWrapper>
            <ResumeFrame src={resume} frameBorder={0} >
            </ResumeFrame>
          </OuterContainerWrapper>
      </>
    )
  }